@import '../../include';

.container {
  background-color: $colorDark;
}

.wrap {
  @extend %content-container;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
  padding: 60px 0;
  column-gap: 146px;
  margin-bottom: 0;
  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    row-gap: 30px;
    column-gap: 0;
    padding: 60px 20px;
  }
}

.logoContentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 3px;
  border: 1px solid $colorGrey;
  @media #{$phone} {
    grid-column: 1 / 2;
    grid-row: -1 / -2;
    max-width: 229px;
    margin: 0 auto;
  }
}
.LogoWrap {
  margin-bottom: 42px;
  @media #{$scalling-desktop} {
    img {
      width: 9.125rem;
      height: auto;
    }
  }
}

.socialsWrap {
  display: flex;
  gap: 20px;
}

.socialIcon {
  margin-bottom: 20px;
  & svg {
    width: 2.22em;
    height: auto;
  }
}

.copyright {
  width: 90px;
  font-size: 10px;
  color: $colorGrey;
}

.contactsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media #{$phone} {
    align-items: center;
  }
}

.contactItemTitleWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  @media #{$phone} {
    justify-content: center;
  }
}
.contactItem {
  @media #{$phone} {
    text-align: center;
  }
}

.contactItemTitle {
  font-size: 20px;
  color: $colorWhite;
}

.contactItemText {
  font-size: 16px;
  color: $colorGreyLight;
}

.contactLinkWrap {
  display: flex;
  flex-direction: column;
}

.contactLink {
  text-decoration: none;
}

.contactLinkText {
  color: $colorWhite;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $colorAccent;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.contactItem:nth-child(2) .bankText {
  margin-bottom: 10px;
}

.menuWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  @media #{$phone} {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
}

.menuList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.menuLink {
  text-decoration: none;
}
