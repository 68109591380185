@import '../../../include';

.containerDesktop {
  position: relative;
  display: flex;
  flex-direction: column;
}
.containerMobile {
  display: flex;
}

.wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.currentContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.langicon {
  width: 1.5em;
  height: auto;
}

.shevron {
  transition: transform 0.5s ease;
}

.shevronUp {
  transform: rotate(180deg);
}


.optionsContainerDesktop {
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background-color: $colorWhite;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.optionsContainerMobile {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 20px;
}

.optionsLink {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid $colorDark;

  &:hover,
  &.optionsLinkActive {
    & > a {
      color: $colorAccent;
    }
  }
}

.optionsLink > a {
  color: $colorDark;
  text-decoration: none;
  font-size: 16px;
}

.optionsLink:last-child,
.optionsLinkMonile {
  border-bottom: none;
}