@import '~reset.css';
@import 'src/include';
@import 'scroll-bar-styling';
@import './scale';

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;

  font-family: var(--font-exo2), sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  font-feature-settings: 'lnum';
  color: $colorDark;

  background-color: $colorWhite;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  ::-moz-focus-inner {
    border: 0;
  }
}

.hideOnPhone {
  @media #{$phone} {
    display: none;
  }
}

.hideOnMobilePhone {
  @media #{$mobile-phone} {
    display: none;
  }
}

.hideOnTabletAndDesktop {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnDesktopAndTablet {
  @media #{$phone} {
    display: none;
  }
}

.visibleOnPhone {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnTablet {
  @media #{$tablet-and-desktop} {
    display: none;
  }
  @media #{$mobile-phone} {
    display: none;
  }
}

.visibleOnMobilePhone {
  @media #{$mobile-phone-and-desktop} {
    display: none;
  }
}
