@import '../../include';

.headerBox {
  position: fixed;
  z-index: 999;
  top: 0;
  background: $colorWhite;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  &[unwrapper-node-type] {
    // Prevent hide container on unwrap menu
    display: block !important;
  }

  &.mobileMenuOpened {
    box-shadow: none;
  }
}

.headerContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc((100vw - 1320px) / 2);
    height: 100%;
    background-color: $colorAccent;
  }

  @media #{$phone} {
    min-height: 70px;
  }
}

$leftContainerAngleWidth: calc(100% - 45px);

.leftContainer {
  position: absolute;
  top: 0;
  left: calc(((100% - 1320px) / 2) - 60px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 260px;
  height: 100%;
  padding-left: 30px;
  background-color: $colorAccent;
  clip-path: polygon(0 0, 100% 0, $leftContainerAngleWidth 100%, 0% 100%);
}

.logo {
  @media #{$scalling-desktop} {
    img {
      width: 10.125rem;
      height: auto;
    }
  }

}

.menuContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: left;

  @media #{$phone} {
    display: none;
  }
}

.menuContainerTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: $colorDark;
  padding: 10px 90px;
  width: 100%;
  color: $colorWhite;
}

.menuContainerTopLink {
  text-decoration: none;
}

.menuContainerTopItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  font-size: 14px;
  border-right: 1px solid $colorGrey;
  & > svg {
    width: 1.5rem;
    height: auto;
  }
}

.menuContainerTopText {
  color: $colorWhite;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $colorAccent;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.menuContainerTopSocialsWrap {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  & div {
    & > svg {
      width: 1.5rem;
      height: auto;
    }
  }
}

.menuContainerBottom {
  display: flex;
  flex-shrink: 0; // Prevent new lines in menu
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  padding: 15px 90px;
  background-color: $colorWhite;
  width: 100%;
}

.menuContainerBottomNav {
  display: flex;
  gap: 30px;
}

.menuContainerBottomLink {
  position: relative;
  align-items: center;
  justify-content: center;
  color: $colorDark;
  text-decoration: none;
  padding-bottom: 5px;

  transition: 125ms linear color;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 0;
    height: 2px;
    background: $colorAccent;
    transition: width 0.4s, left 0.4s;
  }

  &.menuLinkActive {
    color: $colorAccent;
  }

  &:hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }
}

.menuContainerMobile {
  display: none;
  @media #{$phone} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
  }
}

.burgerButton {
  cursor: pointer;

  display: none;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 40px;

  background: transparent;
  border: none;

  svg {
    display: none;
    color: #000;
  }

  @media #{$phone} {
    display: flex;
  }

  @media #{$phone} {
    margin-right: -10px;
  }
}

.burgerIcon {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  width: 30px;
  height: 22px;

  i {
    transform-origin: center right;

    display: block;

    width: 100%;
    height: 3px;

    color: #000;

    background-color: currentcolor;
    border: 0 none;
    border-radius: 3px;

    transition: transform 150ms ease-out;
  }

  i:nth-child(1),
  i:nth-child(3) {
    transform-origin: right center;
    transform: translate(0, 0) rotate(0);
  }

  // stylelint-disable-next-line no-duplicate-selectors
  i:nth-child(2) {
    transform-origin: 20% center;
    transform: scaleX(1);
  }
}

.mobileMenuOpened {
  i:nth-child(1) {
    transform: translate(0, -1px) rotate(-45deg);
  }

  i:nth-child(2) {
    transform: scaleX(0);
  }

  i:nth-child(3) {
    transform: translate(0, 1px) rotate(45deg);
  }
}

.mobileMenu {
  display: none;
}

.mobileMenu[unwrapper-node-type] {
  display: block;
}
