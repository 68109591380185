@import '../../../include';

.container {
  height: 100vh;
}

.content {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  padding-top: 102px;
  height: 100%;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.menuLink {
  position: relative;
  font-size: 16px;
  color: $colorDark;
  text-decoration: none;

  transition: 125ms linear color;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 0;
    height: 2px;
    background: $colorAccent;
    transition: width 0.4s, left 0.4s;
  }
  &:hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }
}

.mobileMenuContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 15px 0;
}

.mobileMenuFooter {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding: 10px 40px;
  background-color: $colorDark;
  width: 100%;
  color: $colorWhite;
  justify-content: center;

}

.mobileMenuFooterBottom {
  display: flex;
  align-items: center;
}

.mobileMenuFooterLink {
  text-decoration: none;
}

.mobileMenuFooterItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  font-size: 14px;
  border-right: 1px solid $colorGrey;
  & > svg {
    width: 1.5rem;
    height: auto;
  }
}

.mobileMenuFooterText {
  color: $colorWhite;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $colorAccent;
  }
}

.mobileMenuFooterSocialsWrap {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  & div {
    & > svg {
      width: 1.5rem;
      height: auto;
    }
  }
}
